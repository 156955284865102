import React, { Component } from "react";
import { exportToExcel } from "../common";
import Select from "react-select";
import "react-dropdown/style.css";
import "./notification.scss";
import {
  List,
  Datagrid,
  TextField,
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  DeleteButton
} from "react-admin";

const exporter = payments => {
  exportToExcel(payments, "notifications");
};

const Dropdown = ({ options, onChange, label }) => (
  <div className="custom-dropdown">
    <span className="label">{label}</span>
    <Select
      defaultValue={{ value: "", label: "All" }}
      options={options}
      onChange={onChange}
    />
  </div>
);

export class NotificationCreate extends Component {
  state = {
    continents: [
      {
        value: "",
        label: "All"
      },
      {
        value: "AF-Africa",
        label: "Africa"
      },
      {
        value: "AN-Antarctica",
        label: "Antarctica"
      },
      {
        value: "AS-Asia",
        label: "Asia"
      },
      {
        value: "EU-Europe",
        label: "Europe"
      },
      {
        value: "NA-North America",
        label: "North America"
      },
      {
        value: "OC-Oceania",
        label: "Oceania"
      },
      {
        value: "SA-South America",
        label: "South America"
      }
    ],
    title: "",
    body: "",
    links: "",
    campaigns: [],
    activeCampaign: "",
    userStatus: [
      { value: "all", label: "All" },
      { value: "active", label: "Active" },
      { value: "inactive", label: "Inactive" }
    ],
    activeUserStatus: "all",
    activeContinent: "",
    countries: [],
    activeCountry: "",
    states: [],
    activeState: "",
    pending: false
  };

  async componentDidMount() {
    const token = localStorage.getItem("token");
    if (token) {
      const campaignsJson = await fetch(
        `${process.env.REACT_APP_BASE_URL}admin/campaigns`,
        {
          headers: { Authorization: token }
        }
      );
      const campaignsData = await campaignsJson.json();
      const transformedCampaigns = [{ value: "", label: "All" }];
      if (campaignsData.length) {
        for (let i = 0; i < campaignsData.length; i++) {
          transformedCampaigns.push({
            value: campaignsData[i].name,
            label: campaignsData[i].name
          });
        }
      }
      this.setState({ campaigns: transformedCampaigns });
    }
  }

  onCampaignChange = data => {
    this.setState({ activeCampaign: data.value });
  };
  onUserStatusChange = data => {
    this.setState({ activeUserStatus: data.value });
  };

  onContinentChange = async data => {
    const value = String(data.value).split("-")[1];
    const jsonData = await fetch(
      `${process.env.REACT_APP_BASE_URL}data/countries`
    );
    const fetchData = await jsonData.json();
    const mainData = [{ value: "", label: "All" }];
    if (value) {
      for (let i = 0; i < fetchData[value].length; i++) {
        mainData.push({
          label: fetchData[value][i].countryName,
          value:
            fetchData[value][i].countryID +
            "-" +
            fetchData[value][i].countryName
        });
      }
    }
    this.setState({ activeContinent: value });
    this.setState({ countries: mainData });
  };

  onTitleChange = e => {
    this.setState({ title: e.target.value });
  };
  onBodyChange = e => {
    this.setState({ body: e.target.value });
  };
  onLinkChange = e => {
    this.setState({ links: e.target.value });
  };

  onCountryChange = async data => {
    const value = String(data.value).split("-");
    if (value[0]) {
      const jsonData = await fetch(
        `${process.env.REACT_APP_BASE_URL}data/states/${value[0]}`
      );
      const fetchData = await jsonData.json();
      const mainData = [{ value: "", label: "All" }];
      for (let i = 0; i < fetchData.length; i++) {
        mainData.push({
          label: fetchData[i].stateName,
          value: fetchData[i].stateName
        });
      }
      this.setState({ activeCountry: value[1] });
      this.setState({ states: mainData });
    } else {
      this.setState({ states: [{ value: "", label: "All" }] });
    }
  };
  onStateChange = data => {
    this.setState({ activeState: data.value });
  };

  onBtnClick = async () => {
    const token = localStorage.getItem("token");
    const data = {
      title: this.state.title,
      body: this.state.body,
      campaign: this.state.activeCampaign,
      continent: this.state.activeContinent,
      country: this.state.activeCountry,
      links: this.state.links,
      userStatus: this.state.activeUserStatus,
      state: this.state.activeState
    };
    if (token) {
      this.setState({ pending: true });
      const jsonData = await fetch(
        `${process.env.REACT_APP_BASE_URL}admin/notifications`,
        {
          method: "POST",
          headers: { "content-type": "application/json", Authorization: token },
          body: JSON.stringify(data)
        }
      );
      const fetchData = await jsonData.json();
      this.setState({ pending: false });

      if (!fetchData.message) {
        this.props.history.goBack();
      } else {
        alert(fetchData.message);
      }
    }
  };

  render() {
    return (
      <div className="custom-create">
        <div className="d-flex flex-column">
          <div className="boo d-flex flex-column">
            <span className="label">Notification title</span>
            <input
              maxLength="400"
              onChange={e => this.onTitleChange(e)}
              className="custom-input"
            />
          </div>
          <div className="boo d-flex flex-column">
            <span className="label">Notification Body</span>
            <textarea
              rows="3"
              onChange={e => this.onBodyChange(e)}
              className="custom-input"
            ></textarea>
            {/* <input
              maxLength="1000"
              
            /> */}
          </div>
          <div className="boo d-flex flex-column">
            <span className="label">Notification Links</span>
            <input
              onChange={e => this.onLinkChange(e)}
              className="custom-input"
            />
          </div>
          <Dropdown
            label="Users"
            options={this.state.userStatus}
            onChange={this.onUserStatusChange}
          />
          <Dropdown
            label="Select campaign"
            options={this.state.campaigns}
            onChange={this.onCampaignChange}
          />
          <Dropdown
            label="Select continent"
            options={this.state.continents}
            onChange={this.onContinentChange}
          />
          <Dropdown
            label="Select country"
            options={this.state.countries}
            onChange={this.onCountryChange}
          />
          <Dropdown
            label="Select State"
            options={this.state.states}
            onChange={this.onStateChange}
          />
          <div>
            <button
              disabled={this.state.pending}
              onClick={this.onBtnClick}
              className="custom-btn"
            >
              {this.state.pending ? "Sending" : "Send"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export const NotificationShow = props => {
  return (
    <Show title="Notified users" {...props}>
      <TabbedShowLayout>
        <Tab label="Notification filter">
          <TextField label="Continent" source="filter.continent" />
          <TextField label="Country" source="filter.country" />
          <TextField label="Users" source="filter.userStatus" />
          <TextField label="Campaign" source="filter.campaign" />
          <TextField label="State" source="filter.state" />
        </Tab>
        <Tab label="Users">
          <ReferenceManyField
            reference="notifications"
            target="notification_id"
            addLabel={false}
          >
            <Datagrid>
              {/* <TextField label="Total EMI" source="totalEmi" /> */}
              <TextField sortable={false} source="email" />
              <TextField sortable={false} source="legalName" />
              <TextField sortable={false} source="country" />
              <TextField sortable={false} source="continent" />
              <TextField sortable={false} source="phone" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default props => (
  <List exporter={exporter} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="show">
      <TextField sortable={false} source="title" />
      <TextField sortable={false} source="body" />
      <TextField sortable={false} source="createdOn" />
      <TextField sortable={false} source="triggeredTo" />
      <DeleteButton />
    </Datagrid>
  </List>
);
