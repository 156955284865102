import React from "react";
import { exportToExcel } from "../common";
import { List, Datagrid, TextField } from "react-admin";

const exporter = payments => {
  exportToExcel(payments, "messages");
};

const MessagePanel = ({ record }) => (
  <div dangerouslySetInnerHTML={{ __html: record.message }}></div>
);

export default props => (
  <List exporter={exporter} bulkActionButtons={false} {...props}>
    <Datagrid expand={<MessagePanel />}>
      <TextField sortable={false} source="name" />
      <TextField sortable={false} source="email" />
      <TextField sortable={false} source="subject" />
      <TextField sortable={false} source="receivedOn" />
    </Datagrid>
  </List>
);
