import React from "react";
import { exportToExcel } from "../common";
import { List, Datagrid, TextField } from "react-admin";
import { useState } from "react";

const exporter = payments => {
  exportToExcel(payments, "mails");
};

const PostPanel = ({ id, record, resource }) => {
  const [sent, setSent] = useState(false);

  return (
    <button
      disabled={!(record.status !== "success" && !sent)}
      onClick={() => {
        const token = localStorage.getItem("token");
        setSent(true);
        fetch(process.env.REACT_APP_BASE_URL + "admin/resend/" + id, {
          headers: {
            authorization: token
          }
        })
          .then(res => res.json())
          .then(data => {
            alert(data);
          });
      }}
    >
      Resend
    </button>
  );
};

export default props => (
  <List exporter={exporter} bulkActionButtons={false} {...props}>
    <Datagrid expand={<PostPanel />}>
      <TextField sortable={false} source="userName" />
      <TextField sortable={false} source="email" />
      <TextField sortable={false} source="amount" />
      <TextField sortable={false} source="status" />
      <TextField sortable={false} source="dueDate" />
      <TextField sortable={false} source="dueFor" />
    </Datagrid>
  </List>
);
