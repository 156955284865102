import React from "react";
import { exportToExcel } from "../common";
import {
  List,
  Datagrid,
  Filter,
  // AutocompleteArrayInput,
  TextField,
  TextInput,
  Edit,
  BooleanInput,
  SimpleForm,
  DateInput
} from "react-admin";

const exporter = payments => {
  const arrangedPayments = payments.map(payment => {
    return {
      "Donor name": payment.donorName,
      Continent: payment.continent,
      Country: payment.country,
      Mobile: payment.mobile,
      "Start date": payment.startDate,
      "Total EMI": payment.totalEMI,
      "EMI Pending": payment.EMIPending,
      "Donation type": payment.donationType,
      "Donation Method": payment.donationMethod
    };
  });
  exportToExcel(arrangedPayments, "Ongoing Pledge");
};

export const EditPayment = props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <BooleanInput label="Payment is refunded" source="refunded" />
      </SimpleForm>
    </Edit>
  );
};

const PaymentsFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="search" alwaysOn />
      <DateInput label="From Date" source="fromDate" />
      <DateInput label="To Date" source="toDate" />
    </Filter>
  );
};

export default props => (
  <List
    filters={<PaymentsFilter />}
    exporter={exporter}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid>
      <TextField sortable={false} source="donorName" />
      <TextField sortable={false} source="continent" />
      <TextField sortable={false} source="country" />
      <TextField sortable={false} source="mobile" />
      <TextField sortable={false} source="startDate" />
      <TextField sortable={false} label="Total EMI" source="totalEMI" />
      <TextField sortable={false} label="EMI Pending" source="EMIPending" />
      <TextField sortable={false} label="Overdue EMI" source="overdueEMI" />
      <TextField sortable={false} source="donationType" />
      <TextField sortable={false} source="donationMethod" />
    </Datagrid>
  </List>
);
