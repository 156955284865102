import React from "react";
import { exportToExcel } from "../common";
import {
  List,
  Datagrid,
  Filter,
  // AutocompleteArrayInput,
  TextField,
  TextInput,
  DateInput
} from "react-admin";

const exporter = payments => {
  const arrangedPayments = payments.map(payment => {
    return {
      "Date of donation": payment.dateOfPayment,
      "Donor name": payment.name,
      Continent: payment.continent,
      Country: payment.country,
      Mobile: payment.mobile,
      "Email ID": payment.email,
      "Donation amount": payment.amount,
      "Donation method": payment.paymentMethod,
      "Donation type": payment.paymentType,
      "Donation receipt ID": payment.id
    };
  });
  exportToExcel(arrangedPayments, "receipts");
};

const PaymentsFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="search" alwaysOn />
      {/* <AutocompleteArrayInput
        source="continents"
        choices={[
          { id: "programming", name: "Programming" },
          { id: "lifestyle", name: "Lifestyle" },
          { id: "photography", name: "Photography" }
        ]}
      /> */}
      {/* <AutocompleteArrayInput
        source="countries"
        choices={[
          { id: "programming", name: "Programming" },
          { id: "lifestyle", name: "Lifestyle" },
          { id: "photography", name: "Photography" }
        ]}
      /> */}
      <DateInput label="From Date" source="fromDate" />
      <DateInput label="To Date" source="toDate" />
    </Filter>
  );
};

export default props => (
  <List
    filters={<PaymentsFilter />}
    exporter={exporter}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid>
      <TextField
        sortable={false}
        source="dateOfPayment"
        label="Date of donation"
      />
      <TextField sortable={false} source="name" label="Donor name" />
      <TextField sortable={false} source="continent" />
      <TextField sortable={false} source="country" />
      <TextField sortable={false} source="mobile" />
      <TextField sortable={false} source="email" label="Email ID" />
      {/* <TextField sortable={false} source="panNo" />
          <TextField sortable={false} source="paymentName" />
      */}
      <TextField sortable={false} source="amount" label="Donation amount" />
      <TextField
        sortable={false}
        source="paymentMethod"
        label="Donation method"
      />
      <TextField sortable={false} source="paymentType" label="Donation type" />
      <TextField sortable={false} label="Donation receipt ID" source="id" />
    </Datagrid>
  </List>
);
