import React from "react";
import { exportToExcel } from "../common";
import {
  List,
  Datagrid,
  Filter,
  // AutocompleteArrayInput,
  TextField,
  // DateInput,
  TextInput
} from "react-admin";

const exporter = payments => {
  exportToExcel(payments, "overdues");
};

const PaymentsFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Search user" source="userName" alwaysOn />
      {/* <AutocompleteArrayInput
        source="continents"
        choices={[
          { id: "programming", name: "Programming" },
          { id: "lifestyle", name: "Lifestyle" },
          { id: "photography", name: "Photography" }
        ]}
      />
      <AutocompleteArrayInput
        source="countries"
        choices={[
          { id: "programming", name: "Programming" },
          { id: "lifestyle", name: "Lifestyle" },
          { id: "photography", name: "Photography" }
        ]}
      />
      <DateInput label="From Date" source="fromDate" />
      <DateInput label="To Date" source="toDate" /> */}
    </Filter>
  );
};

export default props => (
  <List
    filters={<PaymentsFilter />}
    exporter={exporter}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid>
      <TextField sortable={false} source="continent" />
      <TextField sortable={false} source="country" />
      <TextField sortable={false} source="name" />
      <TextField sortable={false} source="amount" />
      <TextField sortable={false} source="dueDate" />
      <TextField label="Donation name" sortable={false} source="paymentName" />
    </Datagrid>
  </List>
);
