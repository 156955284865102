import React from "react";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import {
  List,
  Datagrid,
  TextField,
  Show,
  TextInput,
  Filter,
  TabbedShowLayout,
  ReferenceManyField,
  Tab,
  SelectInput,
  DateInput
} from "react-admin";
import { exportToExcel } from "../common";

const exporter = users => {
  const usersForExport = users.map(user => {
    return {
      Continent: user.continent,
      Country: user.country,
      "Legal name": user.legalName,
      Email: user.email,
      "Registered on": user.registeredOn,
      Phone: user.phone,
      Source: user.source,
    };
  });
  exportToExcel(usersForExport, "users");
};

export const UserShow = props => {
  return (
    <Show title="User details" {...props}>
      <TabbedShowLayout>
        <Tab label="General Details">
          <TextField source="continent" />
          <TextField source="country" />
          <TextField source="legalName" />
          <TextField source="referralCode" />
          <TextField source="email" />
          <TextField source="phone" />
        </Tab>
        <Tab label="Purchases">
          <ReferenceManyField
            reference="users"
            target="user_id"
            addLabel={false}
          >
            <Datagrid>
              <TextField label="Donation name" source="paymentName" />
              <TextField label="Donation type" source="paymentType" />
              <TextField label="Donation status" source="paymentStatus" />
              <TextField label="Donation amount" source="amount" />
              <TextField label="Total EMI" source="totalEmi" />
              <TextField label="EMI left" source="emiLeft" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const UserFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="userName" alwaysOn />
      <SelectInput
        alwaysOn
        allowEmpty={false}
        source="userStatus"
        choices={[
          { id: "all", name: "All" },
          { id: "active", name: "Active" },
          { id: "inactive", name: "Inactive" }
        ]}
      />
      <DateInput alwaysOn label="From Date" source="fromDate" />
      <DateInput alwaysOn label="To Date" source="toDate" />
    </Filter>
  );
};

export default props => (
  <List
    filters={<UserFilter />}
    exporter={exporter}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField sortable={false} source="continent" />
      <TextField sortable={false} source="country" />
      <TextField sortable={false} source="legalName" />
      <TextField sortable={false} source="email" />
      <TextField sortable={false} source="registeredOn" />
      <TextField sortable={false} source="phone" />
      <TextField sortable={false} source="source" />
    </Datagrid>
  </List>
);
