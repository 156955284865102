import React, { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  CreateButton,
  TextField,
  AutocompleteArrayInput,
  EditButton,
  Edit,
  ImageInput,
  ImageField,
} from "react-admin";

const PostActions = ({ basePath }) => {
  return <CreateButton basePath={basePath} />;
};

const CustomAutocompleteArrayInput = () => {
  const [products, setProducts] = useState([
    { id: "5d57b4c56f4b405768be30c1", name: "Radharani Coin" },
  ]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetch(`${process.env.REACT_APP_BASE_URL}admin/campaigns/products`, {
        headers: { Authorization: token },
      })
        .then((res) => res.json())
        .then((data) => setProducts(data));
    }
  }, []);

  return <AutocompleteArrayInput source="products" choices={products} />;
};

export const campaignEdit = (props) => {
  return (
    <Edit title="Edit campaign details" {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput multiline source="description" />
        <DateInput label="Expiration date" source="dateOfExpiration" />
        <ImageInput
          // multiple
          source="imgs"
          label="Campaign pictures"
          accept="image/*"
          placeholder={<p>Drop campaign picture here</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export const CampaignCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput label="Campaign Name" source="name" />
        <TextInput
          multiline
          label="Campaign Description"
          source="description"
        />
        <CustomAutocompleteArrayInput />
        <ImageInput
          // multiple
          source="imgs"
          label="Campaign pictures"
          accept="image/*"
          placeholder={<p>Drop campaign picture here</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <DateInput
          label="Campaign expiration date"
          source="dateOfExpiration"
          defaultValue={new Date()}
        />
      </SimpleForm>
    </Create>
  );
};

export default (props) => (
  <List actions={<PostActions />} bulkActionButtons={false} {...props}>
    <Datagrid>
      <TextField sortable={false} label="Campaign name" source="name" />
      {/* <TextField
        sortable={false}
        label="Campaign description"
        source="description"
      /> */}
      <TextField sortable={false} source="dateOfExpiration" />
      <TextField sortable={false} source="createdAt" />
      <TextField sortable={false} source="amountRaised" />
      <EditButton />
    </Datagrid>
  </List>
);
