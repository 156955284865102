import React from "react";
import { exportToExcel } from "../common";
import { List, Datagrid, Filter, TextField, DateInput } from "react-admin";

const exporter = (mainData) => {
  const data = mainData.map((item) => {
    return {
      "Error Type": item.errorType,
      "Occured On": item.createdOn,
      Message: item.errMsg,
    };
  });
  exportToExcel(data, "Narasimha Yagya Data");
};

export default (props) => (
  <List exporter={exporter} bulkActionButtons={false} {...props}>
    <Datagrid>
      <TextField sortable={false} source="errorType" />
      <TextField sortable={false} label="Occured On" source="createdOn" />
      <TextField sortable={false} source="errMsg" />
    </Datagrid>
  </List>
);
