import React from "react";
import { exportToExcel } from "../common";
import { List, Datagrid, TextField } from "react-admin";

const exporter = (payments) => {
  const arrangedPayments = payments.map((payment) => {
    return {
      "Donor name": payment.name,
      "Donor Email": payment.email,
      "Donor PAN": payment.panNo,
      "Donor address": payment.address,
      Continent: payment.continent,
      Country: payment.country,
      "Date of donation": payment.dateOfPayment,
      "Donation method": payment.paymentMethod,
      Amount: payment.amount,
      "Currency Type": payment.currencyType,
      "Donation name": payment.paymentName,
      "Transaction ID": payment.transactionId,
    };
  });
  exportToExcel(arrangedPayments, "all-payments");
};

export default (props) => (
  <List bulkActionButtons={false} exporter={exporter} {...props}>
    <Datagrid>
      <TextField label="Donor name" sortable={false} source="name" />
      <TextField label="Donor email" sortable={false} source="email" />
      <TextField sortable={false} source="continent" />
      <TextField sortable={false} source="country" />
      <TextField
        sortable={false}
        label="Date of donation"
        source="dateOfPayment"
      />
      <TextField
        label="Donation method"
        sortable={false}
        source="paymentMethod"
      />
      <TextField sortable={false} source="amount" />
      <TextField sortable={false} source="currencyType" />
      <TextField sortable={false} source="panNo" />
      <TextField sortable={false} source="address" />
      <TextField label="Donation name" sortable={false} source="paymentName" />
      <TextField
        label="Donation status"
        sortable={false}
        source="paymentStatus"
      />
      <TextField sortable={false} source="active" />
    </Datagrid>
  </List>
);
