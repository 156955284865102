import React from "react";
import { exportToExcel } from "../common";
import { List, Datagrid, TextField } from "react-admin";

const exporter = payments => {
  exportToExcel(payments, "completed-pujas");
};

export default props => {
  return (
    <List exporter={exporter} bulkActionButtons={false} {...props}>
      <Datagrid>
        <TextField sortable={false} source="name" />
        <TextField sortable={false} source="email" />
        <TextField sortable={false} source="occasion" />
        <TextField sortable={false} source="pujaName" />
        <TextField sortable={false} source="points" />
        <TextField
          sortable={false}
          label="Date of reedem"
          source="dateOfPayment"
        />
        <TextField sortable={false} source="dateOfPuja" />
      </Datagrid>
    </List>
  );
};
