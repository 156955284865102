import React, { useState } from "react";
import { List, Datagrid, TextField } from "react-admin";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { exportToExcel } from "../common";

const exporter = (payments) => {
  exportToExcel(payments, "upcomming-pujas");
};

const PostPanel = ({ id, record }) => {
  const date = moment(record.dateOfPuja, "DD-MM-YYYY");
  const canConfirm = moment().diff(date, "days") > 0;
  const [open, setOpen] = useState(false);
  const [btn, setBtn] = useState(
    record.confirmed ? "Confirmed" : "Send Confirmation Mail"
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = () => {
    setOpen(false);
    if (!canConfirm) return;
    const token = localStorage.getItem("token");
    setBtn("Sending confirmation");
    fetch(process.env.REACT_APP_BASE_URL + "puja/confirm/" + id, {
      headers: {
        authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setBtn(data);
      });
  };

  return (
    <>
      <button
        disabled={btn !== "Send Confirmation Mail"}
        onClick={handleClickOpen}
      >
        {btn}
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {canConfirm
            ? "Do you want to send the confirmation mail?"
            : "You can not send confirmation mail before date"}
        </DialogTitle>
        <DialogActions>
          {canConfirm && (
            <>
              <Button onClick={handleSend} color="primary">
                Yes
              </Button>
              <Button onClick={handleClose} color="primary" autoFocus>
                No
              </Button>
            </>
          )}
          {!canConfirm && (
            <Button onClick={handleClose} color="primary" autoFocus>
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default (props) => (
  <List exporter={exporter} bulkActionButtons={false} {...props}>
    <Datagrid expand={<PostPanel />}>
      <TextField sortable={false} source="name" />
      <TextField sortable={false} source="email" />
      <TextField sortable={false} source="occasion" />
      <TextField sortable={false} source="pujaName" />
      <TextField sortable={false} source="points" />
      <TextField
        sortable={false}
        label="Date of reedem"
        source="dateOfPayment"
      />
      <TextField sortable={false} source="dateOfPuja" />
    </Datagrid>
  </List>
);
