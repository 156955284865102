import React from "react";
import { exportToExcel } from "../common";
import {
  List,
  Datagrid,
  Filter,
  // AutocompleteArrayInput,
  TextField,
  TextInput,
  Edit,
  EditButton,
  BooleanInput,
  SimpleForm,
  DateInput,
} from "react-admin";

const exporter = (mainData) => {
  const data = mainData.map((item) => {
    return {
      "Date Of Registration": item.registeredOn,
      Name: item.name,
      "Email ID": item.email,
      Country: item.country,
      "Contact No.": item.contactNum,
      State: item.state,
      City: item.city,
      "Family Members Name": item.familyMembersName,
      "Total Family Members": item.totalFamilyMembers,
    };
  });
  exportToExcel(data, "Narasimha Yagya Data");
};

const PaymentsFilter = (props) => {
  return (
    <Filter {...props}>
      <DateInput label="From Date" source="fromDate" />
      <DateInput label="To Date" source="toDate" />
    </Filter>
  );
};

export default (props) => (
  <List
    filters={<PaymentsFilter />}
    exporter={exporter}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid>
      <TextField sortable={false} source="registeredOn" />
      <TextField sortable={false} source="name" />
      <TextField sortable={false} source="email" />
      <TextField sortable={false} source="country" />
      <TextField sortable={false} source="contactNum" />
      <TextField sortable={false} source="state" />
      <TextField sortable={false} source="city" />
      <TextField sortable={false} source="familyMembersName" />
      <TextField sortable={false} source="totalFamilyMembers" />
    </Datagrid>
  </List>
);
