import FileSaver from "file-saver";
import XLSX from "xlsx";

function make_book(data) {
  var ws = XLSX.utils.json_to_sheet(data);
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

  return wbout;
}

function s2ab(s) {
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf); //create uint8array as viewer
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  return view;
}

export const exportToExcel = (data, name) => {
  const book = make_book(data);
  const blob = new Blob([s2ab(book)], { type: "application/octet-stream" });
  FileSaver.saveAs(blob, `${name}.xlsx`);
};
