import React from "react";
import { exportToExcel } from "../common";
import {
  List,
  Datagrid,
  Filter,
  // AutocompleteArrayInput,
  TextField,
  TextInput,
  Edit,
  BooleanInput,
  SimpleForm,
  DateInput,
} from "react-admin";

const exporter = (payments) => {
  const arrangedPayments = payments.map((payment) => {
    return {
      "Donor name": payment.donorName,
      Continent: payment.continent,
      Country: payment.country,
      "Pan no": payment.panNo,
      Mobile: payment.mobile,
      "Start date": payment.startDate,
      "End date": payment.endDate,
      "Last paid": payment.lastPaid,
      "Number Of EMI": payment.numberOfEMI,
      "EMI Amount": payment.EMIAmount,
      "Total donation": payment.totalDonation,
      "Donation Method": payment.donationMethod,
      "Donation Type": payment.donationType,
      Address: payment.address,
      Source: payment.source,
    };
  });
  exportToExcel(arrangedPayments, "Completed Pledge");
};

export const EditPayment = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <BooleanInput label="Payment is refunded" source="refunded" />
      </SimpleForm>
    </Edit>
  );
};

const PaymentsFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="search" alwaysOn />
      <DateInput label="From Date" source="fromDate" />
      <DateInput label="To Date" source="toDate" />
    </Filter>
  );
};

export default (props) => (
  <List
    filters={<PaymentsFilter />}
    exporter={exporter}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid>
      <TextField sortable={false} source="donorName" />
      <TextField sortable={false} source="continent" />
      <TextField sortable={false} source="country" />
      <TextField sortable={false} source="panNo" />
      <TextField sortable={false} source="mobile" />
      <TextField sortable={false} source="startDate" />
      <TextField sortable={false} source="endDate" />
      <TextField sortable={false} source="lastPaid" />
      <TextField sortable={false} label="Number of EMI" source="numberOfEMI" />
      <TextField sortable={false} label="EMI Amount" source="EMIAmount" />
      <TextField sortable={false} source="totalDonation" />
      <TextField sortable={false} source="donationMethod" />
      <TextField sortable={false} source="donationType" />
      <TextField sortable={false} source="address" />
      <TextField sortable={false} source="source" />
    </Datagrid>
  </List>
);
