import React from "react";
import { Admin, Resource, fetchUtils } from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import ListUnsuccessfulPayments from "./pages/ListUnsuccessfulPayments";
import Dashboard from "./pages/Dashboard";
import ListUsers, { UserShow } from "./pages/ListUsers";
import ListWebviews, { CreateWebview, EditWebview } from "./pages/ListWebviews";
import ListCompletedPledge from "./pages/ListCompletedPledge";
import ListOngoingPledge from "./pages/ListOngoingPledge";
import ListFlagDates from "./pages/ListFlagDates";
import NarasimhaYagyaData from "./pages/NarasimhaYagyaData";
import ListPayments, { EditPayment } from "./pages/ListPayments";
import ListReceipts from "./pages/ListReceipts";
import ListOverdues from "./pages/ListOverdues";
import ListCompletedPujas from "./pages/ListComPujas";
import ListUpcomingPujas from "./pages/ListUpPujas";
import ListHomepageCarousel from "./pages/HomepageCarousel";
import ListMessages from "./pages/ListMessages";
import CronMessages from "./pages/CronMessages";
import SmsEmailErrors from "./pages/SmsEmailErrors";
import ListProducts, { ProductCreate, productEdit } from "./pages/ListProducts";
import ListNotifications, {
  NotificationCreate,
  NotificationShow,
} from "./pages/ListNotifications";
import ListCampaigns, {
  CampaignCreate,
  campaignEdit,
} from "./pages/ListCampaigns";
import addUploadCapabilities from "./components/addUploadCapabilities";
import authProvider from "./components/authProvider";
// icons
import UsersIcon from "@material-ui/icons/Group";
import DoneAll from "@material-ui/icons/DoneAll";
import Schedule from "@material-ui/icons/Schedule";
import CronIcon from "@material-ui/icons/AvTimer";
import WarningIcon from "@material-ui/icons/Warning";
import FlagIcon from "@material-ui/icons/AssistantPhoto";
import ChatIcon from "@material-ui/icons/Chat";
import ReceiptIcon from "@material-ui/icons/Receipt";
import LabelIcon from "@material-ui/icons/Label";
import InfoIcon from "@material-ui/icons/Info";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AssessmentIcon from "@material-ui/icons/Assessment";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", token);
  return fetchUtils.fetchJson(url, options);
};

const App = () => {
  return (
    <Admin
      authProvider={authProvider}
      dataProvider={addUploadCapabilities(
        jsonServerProvider(`${process.env.REACT_APP_BASE_URL}admin`, httpClient)
      )}
    >
      <Resource
        options={{ label: "Dashboard" }}
        name="dashboard"
        list={Dashboard}
      />
      <Resource
        options={{ label: "Webviews" }}
        create={CreateWebview}
        name="webview"
        edit={EditWebview}
        list={ListWebviews}
      />
      <Resource
        options={{ label: "Homepage Carousel" }}
        // create={CreateWebview}
        name="homepagecarousel"
        // edit={EditWebview}
        list={ListHomepageCarousel}
      />
      <Resource
        icon={ReceiptIcon}
        name="payments"
        options={{ label: "Donations App" }}
        edit={EditPayment}
        list={ListPayments}
      />
      <Resource
        icon={ReceiptIcon}
        name="payments-td"
        options={{ label: "Donations TD" }}
        edit={EditPayment}
        list={ListPayments}
      />
      <Resource
        icon={ReceiptIcon}
        name="receipts"
        options={{ label: "Donation Receipt" }}
        list={ListReceipts}
      />
      <Resource
        icon={ReceiptIcon}
        name="completedpledge"
        options={{ label: "Completed Pledge" }}
        list={ListCompletedPledge}
      />
      <Resource
        icon={ReceiptIcon}
        name="ongoingpledge"
        options={{ label: "Ongoing Pledge" }}
        list={ListOngoingPledge}
      />
      <Resource icon={InfoIcon} name="overdues" list={ListOverdues} />
      <Resource
        icon={WarningIcon}
        options={{ label: "All donations" }}
        name="unsuccessfulpayments"
        list={ListUnsuccessfulPayments}
      />
      <Resource
        create={ProductCreate}
        icon={AssessmentIcon}
        name="products"
        edit={productEdit}
        list={ListProducts}
      />
      <Resource
        icon={LabelIcon}
        name="campaigns"
        create={CampaignCreate}
        edit={campaignEdit}
        list={ListCampaigns}
      />
      <Resource
        show={UserShow}
        icon={UsersIcon}
        name="users"
        list={ListUsers}
      />
      <Resource
        create={NotificationCreate}
        show={NotificationShow}
        icon={NotificationsIcon}
        name="notifications"
        list={ListNotifications}
      />
      <Resource
        options={{ label: "Overdue Mails" }}
        icon={CronIcon}
        name="overduedata"
        list={CronMessages}
      />
      <Resource
        options={{ label: "Notification Mails" }}
        icon={CronIcon}
        name="notificationdata"
        list={CronMessages}
      />
      <Resource
        options={{ label: "Completed Pujas" }}
        icon={DoneAll}
        name="completedpujas"
        list={ListCompletedPujas}
      />
      <Resource
        options={{ label: "Upcoming Pujas" }}
        icon={Schedule}
        name="upcomingpujas"
        list={ListUpcomingPujas}
      />
      <Resource
        show={UserShow}
        icon={FlagIcon}
        options={{ label: "Special dates" }}
        name="specialdates"
        list={ListFlagDates}
      />
      <Resource icon={ChatIcon} name="messages" list={ListMessages} />
      <Resource
        icon={ChatIcon}
        options={{ label: "Narasimha Yagya Data" }}
        name="yagya"
        list={NarasimhaYagyaData}
      />
      <Resource
        icon={ChatIcon}
        options={{ label: "Sms And Email Errors" }}
        name="smsemailerrors"
        list={SmsEmailErrors}
      />
    </Admin>
  );
};

export default App;
