import React from "react";
import { exportToExcel } from "../common";
import {
  List,
  Datagrid,
  Filter,
  // AutocompleteArrayInput,
  TextField,
  TextInput,
  Edit,
  EditButton,
  BooleanInput,
  SelectInput,
  SimpleForm,
  DateInput,
} from "react-admin";

const exporter = (payments) => {
  const arrangedPayments = payments.map((payment) => {
    return {
      "Donor name": payment.name,
      "Donor Email": payment.email,
      "Donor PAN": payment.panNo,
      "Donor address": payment.address,
      Continent: payment.continent,
      Country: payment.country,
      "Date of donation": payment.dateOfPayment,
      "EMI number": payment.emiNumber,
      Quantity: payment.count,
      "Donation method": payment.paymentMethod,
      Amount: payment.amount,
      "Currency Type": payment.currencyType,
      "Donation name": payment.paymentName,
      "Donation type": payment.paymentType,
      "Transaction ID": payment.transactionId,
    };
  });
  exportToExcel(arrangedPayments, "payments");
};

export const EditPayment = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <BooleanInput label="Payment is refunded" source="refunded" />
      </SimpleForm>
    </Edit>
  );
};

const PaymentsFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="search" alwaysOn />
      <SelectInput
        allowEmpty={false}
        source="donationLocation"
        choices={[
          { id: "all", name: "All" },
          { id: "national", name: "National" },
          { id: "international", name: "International" },
        ]}
      />
      <DateInput label="From Date" source="fromDate" />
      <DateInput label="To Date" source="toDate" />
    </Filter>
  );
};

export default (props) => (
  <List
    filters={<PaymentsFilter />}
    exporter={exporter}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid>
      <TextField label="Donor name" sortable={false} source="name" />
      <TextField label="Donor email" sortable={false} source="email" />
      <TextField sortable={false} source="continent" />
      <TextField sortable={false} source="country" />
      <TextField
        sortable={false}
        label="Date of donation"
        source="dateOfPayment"
      />
      <TextField
        label="Donation method"
        sortable={false}
        source="paymentMethod"
      />
      <TextField sortable={false} source="amount" />
      <TextField sortable={false} source="currencyType" />
      <TextField sortable={false} source="emiNumber" />
      <TextField sortable={false} label="Quantity" source="count" />
      <TextField sortable={false} source="panNo" />
      <TextField sortable={false} source="address" />
      <TextField label="Donation name" sortable={false} source="paymentName" />
      <TextField label="Donation type" sortable={false} source="paymentType" />
      <TextField
        sortable={false}
        label="Transaction ID"
        source="transactionId"
      />
      <EditButton />
    </Datagrid>
  </List>
);
