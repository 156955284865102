import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import {
  Button,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
} from "@material-ui/core";
import DragNDrop from "../components/DragNDrop";

export default () => {
  const url = `${process.env.REACT_APP_BASE_URL}admin/homepagecarousel`;
  const token = localStorage.getItem("token");
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [imagesData, setImagesData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const init = async () => {
    const res = await fetch(url, {
      headers: {
        Authorization: token,
      },
    });
    const { data } = await res.json();
    const mainData = data.sort((a, b) => a.order - b.order);

    setImagesData(mainData);
  };

  const updateList = (list) => {
    const mainData = [];
    list.forEach((item, i) => {
      mainData.push({ ...item, order: i + 1 });
    });

    fetch(url, {
      method: "PUT",
      headers: { "content-type": "application/json", Authorization: token },
      body: JSON.stringify(mainData),
    });
  };

  const onDelete = (index) => {
    fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: token,
        "content-type": "application/json",
      },
      body: JSON.stringify({ id: imagesData[index]._id }),
    });
    const newImages = [...imagesData];
    newImages.splice(index, 1);

    setImagesData(newImages);
  };

  const handleUpload = async () => {
    setUploading(true);
    const formData = new FormData();
    formData.append("carousel", selectedFile);
    formData.append("order", imagesData.length);
    const res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: formData,
    });
    const resData = await res.json();
    const newImages = [...imagesData];
    newImages.push({
      order: imagesData.length,
      url: resData.url,
      _id: resData.id,
    });
    setImagesData(newImages);
    setUploading(true);
    setOpen(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      <Button onClick={() => setOpen(true)} variant="contained" color="primary">
        Upload
      </Button>
      <DragNDrop
        onDelete={onDelete}
        data={imagesData}
        updateList={updateList}
      />
      <Dialog
        open={open}
        onClose={() => {
          if (!uploading) setOpen(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Upload Image</DialogTitle>
        <DialogContent>
          <Typography
            variant="caption"
            component="h6"
            style={{ marginBottom: "10px" }}
          >
            {fileName && fileName.path ? fileName.path : fileName}
          </Typography>
          <div
            styles={{
              padding: "20px",
              border: "3px dashed #eeeeee",
              backgroundColor: "#ccccb3",
              color: "black",
            }}
          >
            <input
              type="file"
              onChange={(e) => setSelectedFile(e.target.files[0])}
            />
            <p>Drag'n'drop images, or click to select files</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={uploading}
            onClick={() => setOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button disabled={uploading} onClick={handleUpload} color="primary">
            {uploading ? "Uploading" : "Upload"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
