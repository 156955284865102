import React, { Component } from "react";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  DeleteButton,
  Create,
  Edit,
  SimpleForm,
  EditButton,
  BooleanInput,
} from "react-admin";

export const EditWebview = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <BooleanInput source="active" />
      </SimpleForm>
    </Edit>
  );
};

export const CreateWebview = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="menuTitle" />
        <TextInput source="url" />
      </SimpleForm>
    </Create>
  );
};

export default (props) => (
  <List bulkActionButtons={false} {...props}>
    <Datagrid>
      <TextField sortable={false} source="menuTitle" />
      <TextField sortable={false} source="url" />
      <TextField sortable={false} source="createdOn" />
      <TextField sortable={false} source="active" />
      {/*<DeleteButton />*/}
      <EditButton />
    </Datagrid>
  </List>
);
