// addUploadFeature.js

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const addUploadFeature = (requestHandler) => (type, resource, params) => {
  const imgs = params.data ? params.data.newImg || params.data.imgs : null;
  if ((type === "CREATE" || type === "UPDATE") && resource === "campaigns") {
    if (imgs && !(imgs instanceof Array)) {
      // NEW CODE HERE (to upload just one file):
      const myFile = imgs;
      if (!myFile.rawFile instanceof File) {
        return Promise.reject("Error: Not a file..."); // Didn't test this...
      }

      return Promise.resolve(convertFileToBase64(myFile))
        .then((picture64) => ({
          src: picture64,
          title: `${myFile.title}`,
        }))
        .then((transformedMyFile) =>
          requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              myFile: transformedMyFile,
            },
          })
        );
    }
  } else if (
    (type === "CREATE" || type === "UPDATE") &&
    resource === "products"
  ) {
    if (imgs && imgs.length >= 1 && typeof imgs[0] !== "string") {
      return Promise.resolve(convertFileToBase64(imgs[0]))
        .then((img1) => {
          return Promise.resolve(convertFileToBase64(imgs[0])).then((img2) => ({
            src: [img1, img2],
            title: [imgs[0], imgs[0]],
          }));
        })
        .then((transformedFiles) =>
          requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              images: transformedFiles,
            },
          })
        );
    }
  }
  return requestHandler(type, resource, params);
};

export default addUploadFeature;
