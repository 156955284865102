import React, { useState } from "react";
import { exportToExcel } from "../common";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  Edit,
  EditButton,
  RadioButtonGroupInput,
  // CheckboxGroupInput,
  BooleanInput,
  NumberInput,
} from "react-admin";

const exporter = (payments) => {
  exportToExcel(payments, "products");
};

export const productEdit = (props) => {
  return (
    <Edit title="Edit campaign details" {...props}>
      <SimpleForm>
        <TextInput label="Product Name" source="name" />
        <TextInput label="Product ID" source="productId" />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <NumberInput label="Product Amount (INR)" source="amountInr" />
          <NumberInput label="Product Amount (USD)" source="amountUsd" />
          <NumberInput label="Product Amount (EUR)" source="amountEur" />
          <NumberInput label="Product Amount (GBP)" source="amountGbp" />
          <NumberInput label="Product Amount (AUD)" source="amountAud" />
          <NumberInput label="Product Amount (CNY)" source="amountCny" />
        </div>
        <TextInput label="Line 1 (currency)" source="line1" />
        <TextInput label="Line 2 (sponsor limit)" source="line2" />
        <TextInput
          multiline
          label="Line 3 (short description)"
          source="line3"
        />
        <TextInput multiline label="Box 1 content" source="box1" />
        <TextInput multiline label="Box 2 content" source="box2" />
        <ImageInput
          multiple
          source="newImg"
          label="Product Images"
          accept="image/*"
          placeholder={<p>Drop product images here</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%"
          }}
        >
          <NumberInput label="Minimum Amount (INR)" source="minInr" />
          <NumberInput label="Minimum Amount (USD)" source="minUsd" />
          <NumberInput label="Minimum Amount (EUR)" source="minEur" />
          <NumberInput label="Minimum Amount (GBP)" source="minGbp" />
          <NumberInput label="Minimum Amount (AUD)" source="minAud" />
          <NumberInput label="Minimum Amount (CNY)" source="minCny" />
        </div> */}
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%"
          }}
        >
          <NumberInput label="Maximum Amount (INR)" source="maxInr" />
          <NumberInput label="Maximum Amount (USD)" source="maxUsd" />
          <NumberInput label="Maximum Amount (EUR)" source="maxEur" />
          <NumberInput label="Maximum Amount (GBP)" source="maxGbp" />
          <NumberInput label="Maximum Amount (AUD)" source="maxAud" />
          <NumberInput label="Maximum Amount (CNY)" source="maxCny" />
        </div> */}
        <BooleanInput label="Only Campaign" source="onlyCampaign" />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        ></div>
      </SimpleForm>
    </Edit>
  );
};

export const ProductCreate = (props) => {
  const [activeType, setActiveType] = useState("");
  const installmentArray = [1, 2, 3, 4, 5].map((item) => {
    return (
      <TextInput
        style={{
          display:
            item === 1 ||
            activeType === "tile" ||
            activeType === "steps" ||
            activeType === "brick"
              ? "inline-block"
              : "none",
        }}
        key={item}
        label={`Item ${item} installments`}
        source={`item${item}`}
      />
    );
  });

  const onChange = (event, value) => {
    setActiveType(value.toLowerCase());
  };

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput label="Product Name" source="name" />
        <TextInput label="Product ID" source="productId" />
        <TextInput label="Line 1 (currency)" source="line1" />
        <TextInput label="Line 2 (sponsor limit)" source="line2" />
        <TextInput
          multiline
          label="Line 3 (short description)"
          source="line3"
        />
        <TextInput
          multiline
          defaultValue="REMINDER: We request you to please complete your pledge payments by 2022 to insure our financial solvency for completing the TOVP on time. Thus, kindly consider making your payment in full or selecting a larger recurring payment to help us meet our urgently needed monthly budget. Thank you."
          label="Box 1 content"
          source="box1"
        />
        <TextInput
          multiline
          defaultValue="ATTENTION: Please select your currency before proceeding with your contribution!"
          label="Box 2 content"
          source="box2"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <NumberInput label="Product Amount (INR)" source="amountInr" />
          <NumberInput label="Product Amount (USD)" source="amountUsd" />
          <NumberInput label="Product Amount (EUR)" source="amountEur" />
          <NumberInput label="Product Amount (GBP)" source="amountGbp" />
          <NumberInput label="Product Amount (AUD)" source="amountAud" />
          <NumberInput label="Product Amount (CNY)" source="amountCny" />
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%"
          }}
        >
          <NumberInput label="Minimum Amount (INR)" source="minInr" />
          <NumberInput label="Minimum Amount (USD)" source="minUsd" />
          <NumberInput label="Minimum Amount (EUR)" source="minEur" />
          <NumberInput label="Minimum Amount (GBP)" source="minGbp" />
          <NumberInput label="Minimum Amount (AUD)" source="minAud" />
          <NumberInput label="Minimum Amount (CNY)" source="minCny" />
        </div> */}
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%"
          }}
        >
          <NumberInput label="Maximum Amount (INR)" source="maxInr" />
          <NumberInput label="Maximum Amount (USD)" source="maxUsd" />
          <NumberInput label="Maximum Amount (EUR)" source="maxEur" />
          <NumberInput label="Maximum Amount (GBP)" source="maxGbp" />
          <NumberInput label="Maximum Amount (AUD)" source="maxAud" />
          <NumberInput label="Maximum Amount (CNY)" source="maxCny" />
        </div> */}
        <BooleanInput label="Only Campaign" source="onlyCampaign" />
        <RadioButtonGroupInput
          onChange={onChange}
          label="Product Type"
          source="type"
          choices={[
            { id: "Coin", name: "coin" },
            { id: "Tile", name: "tile" },
            { id: "Brick", name: "brick" },
            { id: "Steps", name: "steps" },
            { id: "Pillar", name: "pillar" },
          ]}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {installmentArray}
        </div>
        <ImageInput
          multiple
          source="imgs"
          label="Product Images"
          accept="image/*"
          placeholder={<p>Drop product images here</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default (props) => (
  <List exporter={exporter} bulkActionButtons={false} {...props}>
    <Datagrid>
      <TextField sortable={false} source="name" />
      <TextField sortable={false} source="type" />
      <TextField
        sortable={false}
        label="Minimum custom amount"
        source="minCustomAmount"
      />
      <TextField sortable={false} source="amount" />
      <EditButton />
    </Datagrid>
  </List>
);
