import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Select from "react-select";
// import DatePicker from "react-datepicker";
import "../framework.css";
import "./dashboard.scss";

const Dashboard = props => {
  const [type, setType] = useState("year");
  const [usersData, setUserData] = useState([]);
  const [paidPaymentsData, setPaidPaymentsData] = useState([
    {
      name: "Amount",
      data: []
    }
  ]);
  const [overdueData, setOverdueData] = useState([
    {
      name: "Amount",
      data: []
    }
  ]);
  const [upcomingData, setUpcomingData] = useState([
    {
      name: "Amount",
      data: []
    }
  ]);
  const usersOptions = {
    title: {
      text: "Number of users globally",
      align: "center",
      margin: 10,
      offsetX: 30,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "16px",
        color: "#263238"
      }
    },
    colors: ["#28a745"],
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: "50%"
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2
    },
    grid: {
      row: {
        colors: ["#fff", "#f2f2f2"]
      }
    },
    xaxis: {
      labels: {
        rotate: -45
      },
      categories: [
        "Africa",
        "Antarctica",
        "Asia",
        "Europe",
        "North America",
        "Oceania",
        "South America"
      ]
    },
    yaxis: {
      labels: {
        formatter: val => val.toFixed(2)
      },
      title: {
        text: "Donors"
      }
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100]
      }
    }
  };
  const [paidPaymentsOptions, setPaidPaymentsOptions] = useState({
    title: {
      text: "Paid donations",
      align: "center",
      margin: 10,
      offsetX: 30,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "16px",
        color: "#263238"
      }
    },
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: "50%"
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2
    },
    grid: {
      row: {
        colors: ["#fff", "#f2f2f2"]
      }
    },
    xaxis: {
      labels: {
        rotate: -45
      },
      categories: [
        "Africa",
        "Antarctica",
        "Asia",
        "Europe",
        "North America",
        "Oceania",
        "South America"
      ]
    },
    yaxis: {
      labels: {
        formatter: val => val.toFixed(2)
      },
      title: {
        text: "Paid amount in (INR)"
      }
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100]
      }
    }
  });
  const [overdueOptions, setOverdueOptions] = useState({
    title: {
      text: "Overdue donations",
      align: "center",
      margin: 10,
      offsetX: 30,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "16px",
        color: "#263238"
      }
    },
    colors: ["#dc3545"],
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: "50%"
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2
    },
    grid: {
      row: {
        colors: ["#fff", "#f2f2f2"]
      }
    },
    xaxis: {
      labels: {
        rotate: -45
      },
      categories: [
        "Africa",
        "Antarctica",
        "Asia",
        "Europe",
        "North America",
        "Oceania",
        "South America"
      ]
    },
    yaxis: {
      labels: {
        formatter: val => val.toFixed(2)
      },
      title: {
        text: "Overdue amount in (INR)"
      }
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100]
      }
    }
  });
  const [allowedYears, setAllowedYears] = useState([]);
  const [activeYear, setActiveYear] = useState(new Date().getFullYear());
  const onDateChange = data => {
    setActiveYear(data.value);
    fetchData(data.value);
  };
  const [upcomingOptions, setUpcomingOptions] = useState({
    title: {
      text: "Upcoming donations",
      align: "center",
      margin: 10,
      offsetX: 30,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "16px",
        color: "#263238"
      }
    },
    colors: ["#17a2b8"],
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: "50%"
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2
    },
    grid: {
      row: {
        colors: ["#fff", "#f2f2f2"]
      }
    },
    xaxis: {
      labels: {
        rotate: -45
      },
      categories: [
        "Africa",
        "Antarctica",
        "Asia",
        "Europe",
        "North America",
        "Oceania",
        "South America"
      ]
    },
    yaxis: {
      labels: {
        formatter: val => val.toFixed(2)
      },
      title: {
        text: "Upcoming amount in (INR)"
      }
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100]
      }
    }
  });

  const fetchData = year => {
    const token = localStorage.getItem("token");
    fetch(
      process.env.REACT_APP_BASE_URL +
        "admin/dashboard?type=" +
        type +
        "&&year=" +
        (year || activeYear),
      {
        headers: {
          authorization: token
        }
      }
    )
      .then(res => res.json())
      .then(data => {
        const years = [];
        if (data.allowedYears) {
          for (let i = 0; i < data.allowedYears.length; i++) {
            years.push({
              value: data.allowedYears[i],
              label: String(data.allowedYears[i])
            });
          }
        }
        setAllowedYears(years);
        if (data.allContinents)
          setUserData([
            {
              name: "Donors",
              data: Object.values(data.allContinents)
            }
          ]);
        if (data.paidPayments) {
          setPaidPaymentsData([
            {
              name: "Amount",
              data: Object.values(data.paidPayments)
            }
          ]);
        }
        setOverdueData([
          {
            name: "Amount",
            data: Object.values(data.overdue || {})
          }
        ]);
        setUpcomingData([
          {
            name: "Amount",
            data: Object.values(data.upcoming || {})
          }
        ]);
        setPaidPaymentsOptions({
          ...paidPaymentsOptions,
          xaxis: {
            ...paidPaymentsOptions.xaxis,
            categories: Object.keys(data.paidPayments || {})
          }
        });
        setOverdueOptions({
          ...overdueOptions,
          xaxis: {
            ...overdueOptions.xaxis,
            categories: Object.keys(data.overdue || {})
          }
        });
        setUpcomingOptions({
          ...upcomingOptions,
          xaxis: {
            ...upcomingOptions.xaxis,
            categories: Object.keys(data.upcoming || {})
          }
        });
      });
  };

  useEffect(() => fetchData(), [type]);

  return (
    <div className="dashboard">
      <div style={{ margin: "1.5rem", width: "10rem" }}>
        <span style={{ marginBottom: "0.5rem", display: "block" }}>
          Choose year:
        </span>
        <Select options={allowedYears} onChange={onDateChange} />
      </div>
      <div className="d-flex">
        <div className="chart">
          {" "}
          <Chart
            options={usersOptions}
            series={usersData}
            type="bar"
            width={700}
            height={420}
          />
        </div>
        <div className="chart">
          {" "}
          <Chart
            options={paidPaymentsOptions}
            series={paidPaymentsData}
            type="bar"
            width={700}
            height={420}
          />
        </div>
      </div>
      <div className="d-flex">
        <div className="chart">
          <Chart
            options={overdueOptions}
            series={overdueData}
            type="bar"
            width={700}
            height={420}
          />
        </div>
        <div className="chart">
          {" "}
          <Chart
            options={upcomingOptions}
            series={upcomingData}
            type="bar"
            width={700}
            height={420}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
