import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import "./styles.scss";

const SingleDate = props => {
  return (
    <div className="single-date d-flex justify-between w-100">
      <span>{props.date}</span>
      <span onClick={() => props.onClick(props.date)} className="delete">
        Delete
      </span>
    </div>
  );
};

export default props => {
  const [dates, setDates] = useState([]);
  const [pending, setPending] = useState(false);
  const [btn, setBtn] = useState("Add new date");
  const [btnActive, setBtnActive] = useState(false);
  const [date, setDate] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");

    fetch(process.env.REACT_APP_BASE_URL + "admin/specialdates", {
      headers: {
        authorization: token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.length >= 1) {
          if (data.length % 2 === 0) {
            setDates([
              [...data.slice(0, data.length / 2)],
              [...data.slice(data.length / 2, -1)]
            ]);
          } else {
            setDates([
              [...data.slice(0, Math.floor(data.length / 2) - 1)],
              [...data.slice(Math.floor(data.length / 2), -1)]
            ]);
          }
        }
      });
  }, []);

  const deleteDate = date => {
    if (!pending) {
      const token = localStorage.getItem("token");

      fetch(process.env.REACT_APP_BASE_URL + "admin/specialdates/" + date, {
        headers: {
          authorization: token
        }
      })
        .then(res => res.json())
        .then(data => {
          if (data.length >= 1) {
            if (data.length % 2 === 0) {
              setDates([
                [...data.slice(0, data.length / 2)],
                [...data.slice(data.length / 2, -1)]
              ]);
              setPending(false);
            } else {
              setDates([
                [...data.slice(0, Math.floor(data.length / 2) - 1)],
                [...data.slice(Math.floor(data.length / 2), -1)]
              ]);
              setPending(false);
            }
          }
        });
    }
  };

  const allDates = date => {
    if (date) {
      if (date.length >= 1) {
        return date.map(date => (
          <SingleDate onClick={deleteDate} date={date} key={date} />
        ));
      } else {
        return <h1>No dates found</h1>;
      }
    }
  };

  const cancelAddingDate = () => {
    setBtn("Add new date");
    setBtnActive(false);
  };

  const addDate = () => {
    setBtn("Cancel");
    setBtnActive(true);
  };

  const handleBtnClick = () => {
    if (btnActive) cancelAddingDate();
    else addDate();
  };

  const addNewDate = () => {
    if (!pending) {
      const token = localStorage.getItem("token");
      fetch(process.env.REACT_APP_BASE_URL + "admin/specialdates", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: token
        },
        body: JSON.stringify({ date })
      })
        .then(res => res.json())
        .then(data => {
          if (!data.message && data.length >= 1) {
            if (data.length % 2 === 0) {
              setDates([
                [...data.slice(0, data.length / 2)],
                [...data.slice(data.length / 2, -1)]
              ]);
              setPending(false);
            } else {
              setDates([
                [...data.slice(0, Math.floor(data.length / 2) - 1)],
                [...data.slice(Math.floor(data.length / 2), -1)]
              ]);
              setPending(false);
            }
          }
        });
    }
  };

  return (
    <div>
      <div className="d-flex">
        <Button
          color="primary"
          variant="outlined"
          style={{ margin: "0 1.5rem" }}
          onClick={handleBtnClick}
        >
          {btn}
        </Button>
        <div className={btnActive ? "active" : "hidden"}>
          <TextField
            onChange={event => setDate(event.target.value)}
            id="date"
            type="date"
            defaultValue="2017-05-24"
            InputLabelProps={{
              shrink: true
            }}
          />
          <Button onClick={addNewDate} color="primary">
            Add
          </Button>
        </div>
      </div>
      <div className="w-100 d-flex">
        <div className="w-50 d-flex flex-wrap justify-between">
          {allDates(dates[0])}
        </div>
        <div className="w-50 d-flex flex-wrap justify-between">
          {allDates(dates[1])}
        </div>
      </div>
    </div>
  );
};
